import axios from 'axios';
import { wixAxiosConfig } from '@wix/wix-axios-config';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import { APP_TOKEN, PROFILE_WIDGET_APP } from '../constants';

type MembersAreaAppApi = {
  refreshApp?(): Promise<void>;
};

const MEMBERS_AREA_API_URL = 'https://members.wixapps.net/members-area/_api';

wixAxiosConfig(axios, { baseURL: MEMBERS_AREA_API_URL });

export const publishSettingsForMembersAreaApps = async (editorSDK: EditorSDK) => {
  const instance = await editorSDK.document.info.getAppInstance(APP_TOKEN);
  return axios.post('settings/publish', {}, { headers: { Authorization: instance } });
};

export const refreshMembersAreaApps = async (editorSDK: EditorSDK) => {
  const appsToRefresh = [MA_APP_IDS.ABOUT, MA_APP_IDS.FOLLOWERS, MA_APP_IDS.ALL_MEMBERS, PROFILE_WIDGET_APP];

  const appRefreshPromises = appsToRefresh.map(async ({ appDefinitionId }) => {
    const api: MembersAreaAppApi | void = await editorSDK.application.getPublicAPI(APP_TOKEN, { appDefinitionId });

    return api?.refreshApp?.();
  });

  await Promise.all(appRefreshPromises);
};

export const mergeGlobalSettings = async (editorSDK: EditorSDK, settings: Record<string, boolean>) => {
  const instance = await editorSDK.document.info.getAppInstance(APP_TOKEN);
  return axios.put('/settings/merge/global?viewMode=Editor', settings, { headers: { Authorization: instance } });
};
